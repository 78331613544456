<template>
<div id="container">
    <header-container />
    <agencies-content />
</div>
</template>

<script>
import HeaderContainer  from '../layout/HeaderContainer.vue'
import AgenciesContent  from './AgenciesContent.vue'

export default {
    name: 'Agencies',
    components: {
        'header-container': HeaderContainer,
        'agencies-content': AgenciesContent
    }
}
</script>
