<template>
  <div id="container">
    <header-container />
    <div class="m-4 p-4 border border-gray-300 rounded">
      <div>
        <h1
          class="w-full flex flex-column flex-wrap items-center justify-between"
        >
          Document Item
        </h1>
      </div>
      <div class="mt-4" v-if="dataLoaded && document">
        <div class="grid grid-cols-2 gap-2">
          <div>
            <small class="text-gray-500 font-bold">Document Id</small><br />
            <span class="text-staapurple-medium font-bold">{{
              document.id ? document.id : "-"
            }}</span>
          </div>
          <div>
            <small class="text-gray-500 font-bold">Document Type</small><br />
            <span class="font-bold">{{
              document.type ? document.type : "-"
            }}</span>
          </div>
          <div>
            <small class="text-gray-500 font-bold">Request URL</small><br />
            <span class="font-bold break-all">{{
              document.requestUrl ? document.requestUrl : "-"
            }}</span>
          </div>
          <div>
            <small class="text-gray-500 font-bold">Host Name</small><br />
            <span class="font-bold">{{
              document.hostName ? document.hostName : "-"
            }}</span>
          </div>
        </div>
        <div class="grid grid-cols-custom2 mt-2 pt-2 border-t border-gray-300">
          <div class="pr-2 font-bold">Language</div>
          <div>{{ document.language ? document.language : "-" }}</div>
          <div class="pr-2 font-bold">Country</div>
          <div>{{ document.country ? document.country : "-" }}</div>
          <div
            v-if="document.agency && document.agency.id"
            class="pr-2 font-bold"
          >
            Agency Id
          </div>
          <div>{{ document.agency.id ? document.agency.id : "-" }}</div>
          <div class="pr-2 font-bold">Document Type</div>
          <div>{{ document.type ? document.type : "-" }}</div>
          <div class="pr-2 font-bold">Request Url</div>
          <div class="break-all">
            {{ document.requestUrl ? document.requestUrl : "-" }}
          </div>
          <div class="pr-2 font-bold">Request File</div>
          <div class="break-all">
            {{ document.requestFile ? document.requestFile : "-" }}
          </div>
          <div class="pr-2 font-bold">Status</div>
          <div>{{ document.status ? document.status : "-" }}</div>
          <div class="pr-2 font-bold">Referrer</div>
          <div>{{ document.referrer ? document.referrer : "-" }}</div>
          <div class="pr-2 font-bold">Created at</div>
          <div>
            {{
              document.createdAt ? prettyDate(document.createdAt, true) : "-"
            }}
          </div>
          <div class="pr-2 font-bold">Updated at</div>
          <div>
            {{
              document.updatedAt ? prettyDate(document.updatedAt, true) : "-"
            }}
          </div>
        </div>
        <div class="mt-2 pt-2 border-t border-gray-300">
          <small class="text-gray-500 font-bold">Data</small><br />
          <textarea
            class="h-64 transition px-3 py-2 border-2 border-gray-300 block w-full rounded text-gray-800 focus:shadow-outline focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
            :value="document.data"
          />
        </div>
        <div class="mt-2 pt-2 border-t border-gray-300">
          <small class="text-gray-500 font-bold">Xmldata</small><br />
          <textarea
            class="h-64 transition px-3 py-2 border-2 border-gray-300 block w-full rounded text-gray-800 focus:shadow-outline focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
            :value="document.xmlData"
          />
        </div>
        <div class="mt-2 pt-2 border-t border-gray-300">
          <small class="text-gray-500 font-bold">Jsondata</small><br />
          <textarea
            class="h-64 transition px-3 py-2 border-2 border-gray-300 block w-full rounded text-gray-800 focus:shadow-outline focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
            :value="JSON.stringify(document.jsonData)"
          />
        </div>
        <div class="mt-4" v-html="output"></div>
        <div class="mt-4 flex justify-between">
          <back-or-submit
            :back="{ href: '/documents', label: 'Back' }"
            :submit="false"
          />
        </div>
      </div>
      <Loading
        v-else
        type="page-content"
        container_class="bg-black bg-opacity-25 top-0 left-0 fixed z-70 w-screen h-screen flex items-center"
        color="text-staapurple-medium"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import HeaderContainer from "../../layout/HeaderContainer";
import BackOrSubmit from "../../partials/BackOrSubmit.vue";
import Loading from "../../partials/Loading";

export default {
  name: "DocumentDetails",
  components: {
    "header-container": HeaderContainer,
    "back-or-submit": BackOrSubmit,
    Loading,
  },
  data() {
    return {
      dataLoaded: false,
      animate: false,
      animate_class: "animate-spin",
      output: "",
      document: { agency: {} },
    };
  },
  computed: {
    document_details_route() {
      return this.generateUrl(
        `documents/` + this.$route.params.id + "/details"
      );
    },
  },
  mounted() {
    this.getDocumentDetails();
  },
  methods: {
    setDataLoaded(value) {
      this.dataLoaded = value;
    },
    getDocumentDetails: _.debounce(function (animate) {
      this.axios
        .get(this.document_details_route)
        .then((response) => {
          if (response.data) {
            // this.output = response.data.output
            this.document = response.data.document;
          }
          if (animate) {
            this.animate = false;
          }
          this.setDataLoaded(true);
        })
        .catch((error) => console.log(error));
    }, 200),
  },
};
</script>
