<template>
  <svg
    class="h-5 fill-current flex flex-col justify-center"
    viewBox="0 -4 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.39931 1.06817C1.86815 0.599329 2.50404 0.335938 3.16708 0.335938H14.8337C15.4968 0.335938 16.1327 0.59933 16.6015 1.06817C17.0704 1.53701 17.3337 2.1729 17.3337 2.83594V3.65634C17.3339 3.66445 17.3339 3.67257 17.3337 3.68067V11.1693C17.3337 11.8323 17.0704 12.4682 16.6015 12.937C16.1327 13.4059 15.4968 13.6693 14.8337 13.6693H3.16708C2.50404 13.6693 1.86815 13.4059 1.39931 12.937C0.930473 12.4682 0.667081 11.8323 0.667081 11.1693V3.68066C0.666963 3.67256 0.666963 3.66446 0.667081 3.65635V2.83594C0.667081 2.1729 0.930473 1.53701 1.39931 1.06817ZM2.33375 5.22637V11.1693C2.33375 11.3903 2.42154 11.6022 2.57783 11.7585C2.73411 11.9148 2.94607 12.0026 3.16708 12.0026H14.8337C15.0548 12.0026 15.2667 11.9148 15.423 11.7585C15.5793 11.6022 15.6671 11.3903 15.6671 11.1693V5.22637L10.3879 8.74581C10.3878 8.74587 10.3877 8.74592 10.3877 8.74598C9.97689 9.01997 9.49418 9.16619 9.00041 9.16619C8.50665 9.16619 8.02394 9.01997 7.61316 8.74598C7.61308 8.74592 7.613 8.74587 7.61291 8.74581L2.33375 5.22637ZM15.6671 3.22328L9.46316 7.35923L9.46291 7.3594C9.32597 7.45076 9.16504 7.49952 9.00041 7.49952C8.83579 7.49952 8.67486 7.45076 8.53791 7.3594L8.53766 7.35923L2.33375 3.22328V2.83594C2.33375 2.61492 2.42154 2.40296 2.57783 2.24668C2.73411 2.0904 2.94607 2.0026 3.16708 2.0026H14.8337C15.0548 2.0026 15.2667 2.0904 15.423 2.24668C15.5793 2.40296 15.6671 2.61492 15.6671 2.83594V3.22328Z"
    />
  </svg>
</template>
