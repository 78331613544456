<script setup>
import { ref, watch } from "vue";
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxEmpty,
  ComboboxGroup,
  ComboboxInput,
  ComboboxItem,
  ComboboxItemIndicator,
  ComboboxRoot,
  ComboboxSeparator,
  ComboboxTrigger,
  ComboboxViewport,
} from "radix-vue";
import CheckIcon from "@assets/vue/svg/CheckIcon";

const props = defineProps({
  options: Object,
  placeholder: String,
});

const v = ref();
const boxValue = defineModel();

watch(v, (newValue) => {
  if (newValue) {
    const selectedItem = props.options.filter((o) => o.label === newValue);
    boxValue.value = selectedItem[0].value;
  }
});
</script>

<template>
  <ComboboxRoot v-model="v" class="relative">
    <ComboboxAnchor
      class="min-w-[160px] inline-flex item justify-between rounded px-[15px] text-[13px] leading-none h-[35px] gap-[5px] bg-white text outline-none"
    >
      <ComboboxInput
        class="relative !bg-transparent outline-none text-slate-900 h-full placeholder:text-slate-400"
        :placeholder="placeholder"
      />
      <ComboboxTrigger>
        <svg
          class="h-4 w-4 absolute right-7 top-3"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#475569"
            stroke-width="1.66678"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </ComboboxTrigger>
    </ComboboxAnchor>

    <ComboboxContent
      class="absolute z-10 w-full mt-2 min-w-[160px] bg-white overflow-hidden rounded shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
    >
      <ComboboxViewport class="p-[5px]">
        <ComboboxEmpty
          class="text-mauve8 text-xs font-medium text-center py-2"
        />

        <ComboboxGroup>
          <ComboboxItem
            v-for="(option, index) in options.filter(
              (o) => o.value !== null && o.label !== null
            )"
            :key="index"
            class="text-[13px] leading-none rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-opacity-30 data-[highlighted]:bg-staapurple-light data-[highlighted]:text-staapurple-dark"
            :value="option.label"
          >
            <span>
              {{ option.label }}
            </span>
          </ComboboxItem>
          <ComboboxSeparator class="h-[1px] m-[5px]" />
        </ComboboxGroup>
      </ComboboxViewport>
    </ComboboxContent>
  </ComboboxRoot>
</template>
