<template>
    <div>
        <div v-if="type !== 'table'">
            <div :class="container_class">
                <span :class="color" class="w-full block text-center">
                    <font-awesome-icon icon="circle-notch" class="animate-spin" size="4x" />
                </span>
            </div>
        </div>
        <div v-if="type === 'table'">
            <table :class="container_class" class="animate-pulse w-full">
                <tr>
                    <th class="bg-gray-400 h-8 border-8 border-white"></th>
                    <th class="bg-gray-400 h-8 border-8 border-white"></th>
                    <th class="bg-gray-400 h-8 border-8 border-white"></th>
                </tr>
                <tr v-for="i in tableRows" :key="'body-table-row' + i">
                    <td class="bg-gray-300 h-8 border-8 border-white"></td>
                    <td class="bg-gray-300 h-8 border-8 border-white"></td>
                    <td class="bg-gray-300 h-8 border-8 border-white"></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    props: ['color', 'container_class', 'type', 'rows'],
    data() {
        return {
            tableRows: this.rows ? this.rows : 5
        }
    }
}
</script>