<script setup>
import { computed } from "vue";
import { ToastDescription } from "radix-vue";
import { cn } from "@assets/vue/Helpers/utils.js";

const props = defineProps({
  class: String,
});

// `delegatedProps` is a computed property that creates a new object
// containing all properties from `props` except for `class`.
// This is used to pass all other props to the `ToastTitle` component.
const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <ToastDescription
    :class="cn('text-sm opacity-90', props.class)"
    v-bind="delegatedProps"
  >
    <slot />
  </ToastDescription>
</template>
