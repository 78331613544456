<template>
    <div>
        <router-link :to="{'name': 'EditAgency', 'params': { 'id': id}}" v-slot="{ navigate, href }" custom>
            <a class="transition p-2 rounded bg-staagreen-medium text-white hover:bg-staagreen-dark hover:text-white" :href="href" @click="navigate" @keypress.enter="navigate" role="icon-link">
                <font-awesome-icon icon="edit" size="sm" />
            </a>
        </router-link>
        <router-link :to="{'name': 'AgencyDetails', 'params': { 'id': id}}" v-slot="{ navigate, href }" custom>
            <a class="transition p-2 rounded bg-staagreen-medium text-white hover:bg-staagreen-dark hover:text-white" :href="href" @click="navigate" @keypress.enter="navigate" role="icon-link">
                <font-awesome-icon icon="file" size="sm" />
            </a>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'actionButtonsAgencies',    
    props: ['id']
}
</script>