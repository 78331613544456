<template>
<div class="p-4">
    <h1 class="pb-4"> Staa Core is up and running </h1>
    <h2 v-if="info && info.statusOfRPA == true" class="pb-4" >[OK] RPA is up</h2>
    <h2 v-else class="pb-4" style="color:red;" >[X] RPA is down</h2>

    <div v-if="info">
        <div class="p-3">
            Documents ( {{ info.countAllInLastHour }} records processed / last hour ) - ( {{ info.countAllInLastDay }} records processed / last 24 hours )
        </div>

        <div class="md:flex border-solid border-t border-b border-gray-300 md:mb-3">
            <div class="md:flex-1 p-3">
                <p>Total Documents: {{ info.countDocuments }} records</p>
                <p>Candidates: {{ info.countCvDocuments }} records</p>
                <p>Vacancies: {{ info.countVacancyDocuments }} records</p>
            </div>

            <div class="md:flex-1 border-solid border-b md:border-b-0 border-t md:border-t-0 md:border-l md:border-r border-gray-300 p-3">
                <p>Pending: {{ info.countDocumentsPending }} records</p>
                <p>Completed: {{ info.countDocumentsCompleted }} records</p>
                <p>In Progress: {{ info.countDocumentsInProgress }} records</p>
                <p>Error: {{ info.countDocumentsError }} records</p>
            </div>

            <div class="md:flex-1 p-3">
                <p>Scheduled: {{ info.countDocumentsScheduled }} records</p>
                <p>Scheduled CVs: {{ info.countDocumentsScheduledCvs }} records</p>
                <p>Scheduled Vacancies: {{ info.countDocumentsScheduledVacancies }} records</p>
            </div>
        </div>

        <div class="md:flex">
            <a :href="indexRoute" class="block text-center md:flex-1 px-3 py-2.5 text-white hover:text-white bg-staagreen-light hover:bg-staagreen-dark font-base">List Documents</a>
            <a :href="processRoute" class="block text-center md:flex-1 px-3 py-2.5 text-white hover:text-white bg-staapurple-light hover:bg-staapurple-dark font-base">Run process documents command</a>
        </div>
    </div>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'DashboardContent',
    data() {
        return {
            info: null,
            indexRoute: this.generateUrl('documents'),
            processRoute: this.generateUrl('documents/processed'),
            dashboardRoute: this.generateUrl(`vueapi/dashboard`),
            interval: null
        }
    },
    mounted() {
        this.getDashboard()

        // Refresh the Dashboard data every one minute.
        this.interval = setInterval(function () {
            this.getDashboard()
        }.bind(this), 60000)
    },
    beforeDestroy: function(){
        clearInterval(this.interval);
    },
    methods: {
        getDashboard() {
            this.axios.get(this.dashboardRoute).then(response => this.info = response.data)
        }
    }
}
</script>
