/**
 * Custom Date formatting and processing.
 * prettyDate() use examples:
 *   1. short month label - prettyDate("12-12-2012")
 *   2. long month label - prettyDate("12-12-2012", true)
 */

 const DateFormatter = {
    data: function () {
        return {
            ro_lang: 'ro',
            en_lang: 'en'
        }
    },
    computed: {
        month_names: function () {
            return ['January', 
                    'February', 
                    'March', 
                    'April', 
                    'May', 
                    'June',
                    'July', 
                    'August', 
                    'September', 
                    'October', 
                    'November', 
                    'December'
            ]
        }
    },
    methods: {
        dateWithTime: function (date) {
            if (!date) return false

            return date.match(/(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/)
        },
        ROFormattedDate: function (day, month, year, fullMonth) {
            let formatted_date = ''
            formatted_date = day + ' '
            if (!fullMonth) {
                formatted_date += month.substring(0,3) + ' '
            } else {
                formatted_date += month
            }
            formatted_date += ' ' + year
            return formatted_date
        },
        ENFormattedDate: function (day, month, year, fullMonth) {
            let formatted_date = ''
            if (!fullMonth) {
                formatted_date += month.substring(0,3)
            } else {
                formatted_date += month
            }
            formatted_date += ' ' + day + ', ' + year
            return formatted_date
        },
        formattedTime: function (date) {
            let formatted_time = '',
                hours = parseInt(date.getHours()) < 10 ? '0' + date.getHours() : date.getHours(),
                minutes = parseInt(date.getMinutes()) < 10 ? '0' + date.getMinutes() : date.getMinutes(),
                seconds = parseInt(date.getSeconds()) < 10 ? '0' + date.getSeconds() : date.getSeconds()
            formatted_time += ' ' + hours
            formatted_time += (minutes && hours) ? ':' : ''
            formatted_time += minutes ? minutes : ''
            formatted_time += (minutes && seconds) ? ':' : ''
            formatted_time += seconds ? seconds : ''
            return formatted_time
        },
        prettyDate: function (date, fullMonth) {
            // Prettify date with short or long month labels, by language.
            // <[ro] 19 Feb 2021><[en] Feb 19, 2021>
            let formatted_date = ''
            if (date) {
                let with_time = this.dateWithTime(date)
    
                // Make a date object out of the initiallly received string.
                date = new Date(date)
    
                // Build the date fragments.
                let month = this.month_names[date.getMonth()], 
                    day = date.getDate(), 
                    year = date.getFullYear()
    
                // EN language & Default formatting.
                formatted_date = this.ENFormattedDate(day, month, year, fullMonth)
    
                // Append time HH:MM:SS if time exists in the initially received date string, to the formatted date string.
                if (with_time) {
                    formatted_date += this.formattedTime(date)
                }
            }

            return formatted_date
        }
    }
}    

export default (DateFormatter)