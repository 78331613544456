<template>
<div class="m-4 border border-gray-300 bg-white shadow-xl rounded">
    <h1 class="p-4 border-b border-gray-300">Third parties services</h1>
    
    <div class="p-4 flex">
        <div class="p-4 text-white bg-gray-700">Cvlizer credits: </div>
        <div class="p-4 text-white bg-blue-500 font-bold">{{ joinVisionCredit }}</div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ThirdPartiesContent',
    data() {
        return {
            joinVisionCreditRoute: this.generateUrl(`vueapi/thirdparties`),
            joinVisionCredit: ''
        }
    },
    mounted() {
        this.getJoinVisionCredit()
    },
    methods: {
        getJoinVisionCredit() {
            this.axios.get(this.joinVisionCreditRoute).then(response => {
                if (response.data) {
                    this.joinVisionCredit = response.data.joinVisionCredit
                }
            }).catch(error => console.log(error))
        }
    }
}
</script>
