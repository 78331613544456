<template>
  <svg
    class="w-5 h-5 fill-current"
    viewBox="0 -1 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.05372 1.88445C5.83512 1.10305 6.89493 0.664062 8 0.664062C9.10507 0.664062 10.1649 1.10305 10.9463 1.88445C11.7277 2.66585 12.1667 3.72566 12.1667 4.83073V7.33073H13C13.663 7.33073 14.2989 7.59412 14.7678 8.06296C15.2366 8.5318 15.5 9.16769 15.5 9.83073V14.8307C15.5 15.4938 15.2366 16.1297 14.7678 16.5985C14.2989 17.0673 13.663 17.3307 13 17.3307H3C2.33696 17.3307 1.70107 17.0673 1.23223 16.5985C0.763392 16.1297 0.5 15.4938 0.5 14.8307V9.83073C0.5 9.16769 0.763392 8.5318 1.23223 8.06296C1.70107 7.59412 2.33696 7.33073 3 7.33073H3.83333V4.83073C3.83333 3.72566 4.27232 2.66585 5.05372 1.88445ZM5.5 7.33073H10.5V4.83073C10.5 4.16769 10.2366 3.5318 9.76777 3.06296C9.29893 2.59412 8.66304 2.33073 8 2.33073C7.33696 2.33073 6.70107 2.59412 6.23223 3.06296C5.76339 3.5318 5.5 4.16769 5.5 4.83073V7.33073ZM3 8.9974C2.77899 8.9974 2.56702 9.08519 2.41074 9.24147C2.25446 9.39775 2.16667 9.60972 2.16667 9.83073V14.8307C2.16667 15.0517 2.25446 15.2637 2.41074 15.42C2.56702 15.5763 2.77899 15.6641 3 15.6641H13C13.221 15.6641 13.433 15.5763 13.5893 15.42C13.7455 15.2637 13.8333 15.0517 13.8333 14.8307V9.83073C13.8333 9.60972 13.7455 9.39775 13.5893 9.24147C13.433 9.08519 13.221 8.9974 13 8.9974H3ZM8 10.6641C8.46024 10.6641 8.83333 11.0372 8.83333 11.4974V13.1641C8.83333 13.6243 8.46024 13.9974 8 13.9974C7.53976 13.9974 7.16667 13.6243 7.16667 13.1641V11.4974C7.16667 11.0372 7.53976 10.6641 8 10.6641Z"
    />
  </svg>
</template>
