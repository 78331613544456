<script setup>
import { computed } from "vue";
import { ToastRoot, useForwardPropsEmits } from "radix-vue";
import { toastVariants } from "@assets/vue/components/ui/staatoast/index";
import { cn } from "@assets/vue/Helpers/utils";

const props = defineProps({
  variant: String,
  class: String,
  onOpenChange: Function,
});

const emits = defineEmits(["update:open"]);

// `delegatedProps` is a computed property that creates a new object
// containing all properties from `props` except for `class`.
// This is used to pass all other props to the `ToastTitle` component.
const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;
  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <ToastRoot
    v-bind="forwarded"
    :class="cn(toastVariants({ variant: props.variant }), props.class)"
    @update:open="onOpenChange"
  >
    <slot />
  </ToastRoot>
</template>
