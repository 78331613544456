<template>
  <svg
    class="w-5 h-5 block cursor-pointer"
    id="eye-slash"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-qa="password-show"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.911421 0.90814C1.23686 0.582703 1.7645 0.582703 2.08993 0.90814L4.6241 3.4423C5.9638 2.7127 7.46854 2.32875 9.00124 2.33073C13.1071 2.33098 16.5798 5.02972 17.7482 8.74755C17.7994 8.91052 17.7993 9.0853 17.7479 9.24821C17.2058 10.9657 16.1735 12.4786 14.7884 13.6067L17.0899 15.9081C17.4154 16.2336 17.4154 16.7612 17.0899 17.0867C16.7645 17.4121 16.2369 17.4121 15.9114 17.0867L12.9221 14.0973L12.9215 14.0967L10.179 11.3551L6.64316 7.81922C6.63567 7.81173 6.62829 7.80407 6.62114 7.79637L0.911421 2.08665C0.585985 1.76121 0.585984 1.23358 0.911421 0.90814ZM5.86495 4.68315L7.3077 6.12591C7.81625 5.82621 8.39991 5.66427 9.00026 5.66427C9.88437 5.66427 10.7323 6.01548 11.3574 6.64064C11.9826 7.2658 12.3338 8.1137 12.3338 8.99781C12.3338 9.59816 12.1719 10.1818 11.8722 10.6904L13.6024 12.4206C14.7343 11.5407 15.5936 10.3534 16.0746 8.99694C15.0441 6.08337 12.2652 3.9974 9.00068 3.9974L8.99955 3.9974C7.91316 3.99593 6.84394 4.23167 5.86495 4.68315ZM10.6105 9.4287C10.6478 9.28934 10.6671 9.14457 10.6671 8.99781C10.6671 8.55573 10.4915 8.13175 10.1789 7.81915C9.86632 7.50655 9.44234 7.33093 9.00026 7.33093C8.8535 7.33093 8.70873 7.35029 8.56938 7.38758L10.6105 9.4287ZM2.85377 5.8089C3.22068 6.08675 3.29286 6.60943 3.01501 6.97633C2.54999 7.59038 2.18299 8.27219 1.92658 8.99741C2.95694 11.9112 5.73603 13.9974 9.00068 13.9974C9.47276 13.9978 9.94448 13.9538 10.4083 13.8661C10.8606 13.7806 11.2965 14.0778 11.382 14.5301C11.4675 14.9823 11.1702 15.4182 10.718 15.5037C10.1516 15.6108 9.57646 15.6645 9.00004 15.6641C4.89421 15.6638 1.42158 12.9651 0.253179 9.24724C0.202057 9.08457 0.202065 8.91014 0.253203 8.74747C0.567952 7.74629 1.05275 6.80678 1.68634 5.97013C1.9642 5.60323 2.48687 5.53104 2.85377 5.8089Z"
      fill="#64748B"
    ></path>
  </svg>
</template>
