<script setup>
import { ref } from "vue";
import axios from "axios";
import AuthComponent from "@assets/vue/components/layout/AuthLayout.vue";
import Button from "@assets/vue/components/ui/button/Button.vue";
import CheckIcon from "@assets/vue/svg/CheckIcon.vue";
import HidePasswordIcon from "@assets/vue/svg/HidePasswordIcon.vue";
import MailIcon from "@assets/vue/svg/MailIcon.vue";
import PasswordIcon from "@assets/vue/svg/PasswordIcon.vue";
import ShowPasswordIcon from "@assets/vue/svg/ShowPasswordIcon.vue";

const errorMessage = ref("");
const showPassword = ref(false);
const handleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const errorInputClass =
  "form-input-container-error focus-within:border-red-600";
const normalInputClass = "form-input-container-default ";

const hasError = ref({ username: false, password: false });
const isLoading = ref(false);

const handleResponse = (data) => {
  if (data.errors) {
    // Check if recaptcha should be shown based on the API response
    hasError.value.username = true;
    hasError.value.password = true;
    errorMessage.value = data.errors;
    isLoading.value = false;
  } else {
    window.location = data.redirect;
  }
};
const submitForm = async (e) => {
  e.preventDefault();

  const form = e.target;
  const localData = new FormData(form);

  isLoading.value = true;
  try {
    // Get the csrf token from the server
    const csrfToken = await axios.get("/login_check");
    localData.append("_csrf_token", csrfToken.data.token);
    const response = await axios.post("/login", localData);

    handleResponse(response.data);
  } catch (error) {
    errorMessage.value = error.response
      ? error.response.data.message
      : "Something went wrong, please try again later.";
    isLoading.value = false;
  }
};
</script>

<template>
  <AuthComponent
    main-text="Login"
    secondary-text="Welcome back! Please enter your details."
  >
    <form class="mx-auto" @submit="submitForm">
      <div class="group mb-3">
        <label for="username" class="input-label-default required">
          Email
        </label>
        <div
          :class="hasError.username ? errorInputClass : normalInputClass"
          @click="hasError.username = false"
        >
          <div class="w-5 h-5">
            <MailIcon />
          </div>

          <input
            type="text"
            id="username"
            name="_username"
            class="form-input-default"
            :placeholder="'Email'"
            autocomplete="nope"
          />
        </div>
      </div>
      <div class="group">
        <label for="password" class="input-label-default required">
          Password
        </label>
        <div
          :class="hasError.password ? errorInputClass : normalInputClass"
          @click="hasError.password = false"
        >
          <div class="w-5 h-5">
            <PasswordIcon />
          </div>
          <input
            :type="showPassword ? `text` : `password`"
            id="password"
            name="_password"
            class="form-input-default"
            :placeholder="'Password'"
            autocomplete="nope"
            data-name="pass"
          />
          <div class="w-5 h-5 mr-2" v-if="!showPassword">
            <ShowPasswordIcon @click="handleShowPassword" />
          </div>
          <div class="w-5 h-5 mr-2" v-if="showPassword">
            <HidePasswordIcon @click="handleShowPassword" />
          </div>
        </div>
        <div class="h-6">
          <div
            v-if="hasError.username !== false && hasError.password !== false"
          >
            <div class="validation-error-text" v-if="errorMessage">
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-5">
        <div class="mr-4 flex items-center">
          <input
            class="absolute h-5 w-5 opacity-0 cursor-pointer"
            type="checkbox"
            id="remember_me"
            name="_remember_me"
            checked
            data-qa="remember-me-button"
          />
          <div
            class="mr-2 flex h-5 w-5 flex-shrink-0 items-center justify-center rounded border-2 border-slate-400 bg-white"
          >
            <CheckIcon />
          </div>
          <label class="text-sm leading-tight" for="remember_me"
            >Remember me</label
          >
        </div>

        <a
          class="text-center text-sm font-medium leading-tight text-blue-600"
          href="/reset-password"
          data-qa="reset-password-link"
          >Reset password
        </a>
      </div>

      <p class="pt-6"></p>
      <div class="center aligned field mb-mobile-0">
        <Button :loading="isLoading" data-qa="login-button">Login</Button>
      </div>
    </form>
  </AuthComponent>
</template>
