<template>
    <div class="flex items-center">
        <router-link :to="back.href" v-slot="{ navigate, href }" custom>
            <a :class="submit === false ? 'rounded-r' : ''" class="rounded-l float-left transition py-2 px-5 bg-gray-300 hover:bg-gray-600 hover:text-white" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                {{ back ? back.label : 'Back' }}
            </a>
        </router-link>
        <template v-if="submit !== false">
            <div class="relative z-10 text-gray-700 rounded-full bg-white flex items-center justify-center or-circle">or</div>
            <button @click.prevent.stop="$emit('submit')" class="rounded-r float-left transition py-2 px-5 bg-staagreen-medium hover:bg-staagreen-dark text-white hover:text-white" role="submit-button">
                {{ submit.label ? submit.label : 'Submit' }}
            </button>
        </template>
    </div>
</template>

<script>
export default {
    name: 'BackOrSubmit',
    props: ['back', 'submit']
}
</script>
