import axios from "axios";
import VueAxios from "vue-axios";
import { Toaster } from "@assets/vue/components/ui/staatoast/index";
import "@assets/styles/staa.css";
import { createApp } from "vue";
import Tools from "./Helpers/Tools.js";
import DateFormatter from "./Helpers/DateFormatter";
import { router } from "@assets/vue/router/index.js";
import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faRedo } from "@fortawesome/free-solid-svg-icons/faRedo";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// Register the specific Font Awesome libraries.
library.add(
  faTimes,
  faCheck,
  faBars,
  faUser,
  faCircleNotch,
  faEdit,
  faFile,
  faTrash,
  faChevronUp,
  faChevronDown,
  faExclamationTriangle,
  faRedo,
  faPlus
);

const app = createApp(App);
app.use(VueAxios, axios);
app.mixin(Tools);
app.mixin(DateFormatter);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("Toaster", Toaster);
app.mount("#vueApp");
