import Dashboard from "../components/dashboard/Dashboard";
import Agencies from "../components/agencies/Agencies";
import AgencyDetails from "../components/agencies/show/AgencyDetails";
import EditAgency from "../components/agencies/forms/EditAgency";
import CreateAgency from "../components/agencies/forms/CreateAgency";
import Documents from "../components/documents/Documents";
import ProcessedDocuments from "../components/documents/ProcessedDocuments";
import DocumentDetails from "../components/documents/show/DocumentDetails";
import ThirdParties from "../components/thirdparties/ThirdParties";
import Login from "@assets/vue/components/login/Login";
import { createRouter, createWebHistory } from "vue-router";

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Dashboard,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/agencies",
    name: "Agencies",
    component: Agencies,
  },
  {
    path: "/agencies/new",
    name: "CreateAgency",
    component: CreateAgency,
  },
  {
    path: "/agencies/:id",
    name: "AgencyDetails",
    component: AgencyDetails,
  },
  {
    path: "/agencies/:id/edit",
    name: "EditAgency",
    component: EditAgency,
  },
  {
    path: "/documents",
    name: "Documents",
    component: Documents,
  },
  {
    path: "/documents/processed",
    name: "ProcessedDocuments",
    component: ProcessedDocuments,
  },
  {
    path: "/documents/:id",
    name: "DocumentDetails",
    component: DocumentDetails,
  },
  {
    path: "/third-parties-list",
    name: "thirdparties",
    component: ThirdParties,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
