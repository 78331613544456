<script setup>
import { computed } from "vue";
import { ToastViewport } from "radix-vue";
import { cn } from "@assets/vue/Helpers/utils.js";

const props = defineProps({
  as: {
    type: [String, Object],
    default: "ol",
  },
  asChild: Boolean,
  hotkey: {
    type: Array,
    default: () => ["F8"],
  },
  label: {
    type: String,
    default: "Notifications ({hotkey})",
  },
  class: String,
});

// `delegatedProps` is a computed property that creates a new object
// containing all properties from `props` except for `class`.
// This is used to pass all other props to the `ToastTitle` component.
const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;
  return delegated;
});
</script>

<template>
  <ToastViewport
    v-bind="delegatedProps"
    :class="
      cn(
        'fixed top-0 z-[100] flex gap-2 max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]',
        props.class
      )
    "
  />
</template>
