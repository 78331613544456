<template>
  <svg
    class="w-5 h-5 block cursor-pointer"
    id="eye"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-qa="password-hide"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 15C6.73602 15 3.95625 12.9137 2.92667 9.99999C3.95625 7.08632 6.73602 4.99999 10 4.99999C13.2647 4.99999 16.0438 7.08628 17.0733 9.99999C16.0438 12.9137 13.2647 15 10 15ZM10 3.33333C5.8948 3.33333 2.4209 6.03208 1.25328 9.75033C1.20224 9.91286 1.20224 10.0871 1.25328 10.2497C2.4209 13.9679 5.8948 16.6667 10 16.6667C14.1061 16.6667 17.5791 13.9679 18.7467 10.2497C18.7978 10.0871 18.7978 9.91286 18.7467 9.75033C17.5791 6.03213 14.1061 3.33333 10 3.33333ZM8.82148 8.82148C9.13404 8.50892 9.55797 8.33333 10 8.33333C10.442 8.33333 10.8659 8.50892 11.1785 8.82148C11.4911 9.13404 11.6667 9.55797 11.6667 9.99999C11.6667 10.442 11.4911 10.8659 11.1785 11.1785C10.8659 11.4911 10.442 11.6667 10 11.6667C9.55797 11.6667 9.13404 11.4911 8.82148 11.1785C8.50892 10.8659 8.33333 10.442 8.33333 9.99999C8.33333 9.55797 8.50892 9.13404 8.82148 8.82148ZM10 6.66666C9.11594 6.66666 8.2681 7.01785 7.64297 7.64297C7.01785 8.26809 6.66666 9.11594 6.66666 9.99999C6.66666 10.884 7.01785 11.7319 7.64297 12.357C8.2681 12.9821 9.11594 13.3333 10 13.3333C10.8841 13.3333 11.7319 12.9821 12.357 12.357C12.9821 11.7319 13.3333 10.884 13.3333 9.99999C13.3333 9.11594 12.9821 8.26809 12.357 7.64297C11.7319 7.01785 10.8841 6.66666 10 6.66666Z"
      fill="#64748B"
    ></path>
  </svg>
</template>
