<template>
<div class="h-14 min-h-0 max-h-full">
    <nav class="main-navigation h-14 min-h-0 max-h-full">
        <div class="header-left">
            <header-logo />
            <div class="toggle-hamburger-wrapper">
                <button id="hamburger" class="toggle-hamburger" @click.prevent.stop="toggleHamburger()">
                    <div :class="!showHamburger ? 'hidden' : ''"><font-awesome-icon icon="times" class="text-3xl" /></div>
                    <div :class="showHamburger ? 'hidden' : ''"><font-awesome-icon icon="bars" class="text-3xl" /></div>
                </button>
            </div>
            <div :class="showHamburger ? 'md:visible' : 'hidden md:visible'" class="toggle hamburger-content">
                <main-menu class="main-menu-wrapper" />
                <div class="header-right">
                    <user-dropdown-menu class="md:flex md:items-stretch md:self-stretch" />
                </div>
            </div>
        </div>
    </nav>
</div>
</template>

<script>
import UserDropdownMenu from './UserDropdownMenu'   
import MainMenu from './MainMenu'
import HeaderLogo from './HeaderLogo'

export default {
    name: 'MainNavigation',
    components: {
        'user-dropdown-menu': UserDropdownMenu,
        'main-menu': MainMenu,
        'header-logo': HeaderLogo
    },
    data: function () {
        return {
            showHamburger: false
        }
    },
    methods: {
        toggleHamburger: function () {
            this.showHamburger = !this.showHamburger
        }
    }
}
</script>
