<template>
<div class="mb-20">
    <main-navigation />
</div>
</template>

<script>
import MainNavigation from './MainNavigation'

export default {
    name: 'HeaderContainer',
    components: {
        'main-navigation': MainNavigation
    },
}
</script>
