<template>
  <div
    class="flex flex-wrap items-center px-4 py-3.5 text-white bg-staapurple-light hover:bg-staapurple-dark focus:outline-none focus:bg-stapurple leading-none"
  >
    <DropDown>
      <template v-slot:drop-down>
        <button
          id="user-menu"
          class="flex flex-wrap items-center text-white bg-staapurple-light hover:bg-staapurple-dark focus:outline-none focus:bg-stapurple leading-none"
          aria-label="User menu"
          aria-haspopup="true"
        >
          <font-awesome-icon icon="user" class="text-white mr-2" size="sm" />{{
            buttonText
          }}
        </button>
      </template>
      <template v-slot:drop-down-content>
        <DropdownMenuItem
          class="group leading-none rounded-[3px] h-[25px] px-[5px] relative pl-[25px] select-none outline-none"
          v-for="(item, index) in user_menu.items"
          :key="'user-menu-item-' + index"
          :value="item.name"
        >
          <a
            v-if="item.show === true"
            :href="item.url"
            :title="item.name"
            role="userMenuitem"
            :class="[
              user_menu.link_class,
              item.active_class,
              { 'font-bold': item.active_class === 'active' },
            ]"
            class="menu-link"
            >{{ item.name }}</a
          >
        </DropdownMenuItem>
      </template>
    </DropDown>
  </div>
</template>

<script>
import DropDown from "@assets/vue/components/ui/dropdown/DropDown";
import { DropdownMenuContent, DropdownMenuItem } from "radix-vue";
export default {
  components: {
    DropDown,
    DropdownMenuContent,
    DropdownMenuItem,
  },
  name: "UserDropdownMenu",
  computed: {
    buttonText: function () {
      if (this.getCurrentUser().is_authenticated_remembered) {
        return this.getCurrentUser().username;
      } else {
        return "Not logged in";
      }
    },
    user_menu: function () {
      return {
        link_class:
          "block px-3 py-2 text-sm leading-none text-gray-900 transition duration-150 ease-in-out hover:text-slate-950 focus:outline-none",
        items: [
          {
            name: this.getCurrentUser().is_authenticated_remembered
              ? "Logout"
              : "Login",
            url: this.getCurrentUser().is_authenticated_remembered
              ? this.generateUrl("logout")
              : this.generateUrl("login"),
            active_class: "",
            show: true,
          },
          {
            name: "Third party services",
            url: this.generateUrl("third-parties-list"),
            active_class: this.$route.name === "thirdparties" ? "active" : "",
            show: this.getCurrentUser().is_authenticated_remembered
              ? true
              : false,
          },
        ],
      };
    },
  },
};
</script>
