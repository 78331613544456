<template>
<div id="container">
    <header-container />
    <dashboard-content />
</div>
</template>

<script>
import HeaderContainer  from '../layout/HeaderContainer.vue'
import DashboardContent  from './DashboardContent.vue'

export default {
    name: 'Dashboard',
    components: {
        'header-container': HeaderContainer,
        'dashboard-content': DashboardContent
    }
}
</script>
