<template>
  <div id="container">
    <header-container />
    <div class="m-4 p-4 border border-gray-300 rounded">
      <h1>
        Edit Agency <span class="text-staapurple-medium">{{ id }}</span>
      </h1>
      <div class="mt-4 grid grid-cols-2 gap-2">
        <span>
          <input
            class="transition px-3 py-2 border-2 border-gray-300 block w-full rounded text-gray-800 focus:shadow-outline focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
            :class="errors && errors.name ? 'border-staared-medium' : ''"
            placeholder="Name"
            v-model="agency.name"
          />
          <span v-if="errors && errors.name" class="text-staared-medium">{{
            errors.name
          }}</span>
        </span>
        <span>
          <input
            class="transition px-3 py-2 border-2 border-gray-300 block w-full rounded text-gray-800 focus:shadow-outline focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
            type="email"
            :class="errors && errors.email ? 'border-staared-medium' : ''"
            placeholder="Email"
            v-model="agency.email"
          />
          <span v-if="errors && errors.email" class="text-staared-medium">{{
            errors.email
          }}</span>
        </span>
      </div>
      <div class="mt-4 inline-flex items-center">
        <Switch v-model="agency.isActive" name="isActive" />
      </div>
      <div class="mt-4 flex justify-between">
        <back-or-submit
          :back="{ href: '/agencies', label: 'Back' }"
          :submit="{ label: 'Update' }"
          @submit="EditAgency"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderContainer from "../../layout/HeaderContainer.vue";
import BackOrSubmit from "../../partials/BackOrSubmit.vue";
import Switch from "@assets/vue/components/ui/switch/Switch.vue";
import { useToast } from "@assets/vue/components/ui/staatoast/use-toast";

export default {
  name: "EditAgency",
  props: ["id"],
  components: {
    "header-container": HeaderContainer,
    "back-or-submit": BackOrSubmit,
    Switch,
  },
  mounted() {
    this.getAgencyDetails();
  },
  data() {
    return {
      agency: {
        name: null,
        email: null,
        isActive: false,
      },
      errors: [],
    };
  },
  created() {
    this.toast = useToast().toast;
  },
  methods: {
    EditAgency() {
      let id = this.$route.params.id,
        url =
          this.generateUrl("agencies/" + id + "/update") +
          "?name=" +
          this.agency.name +
          "&email=" +
          this.agency.email +
          "&isActive=" +
          (this.agency.isActive ? 1 : 0);

      if (id) {
        this.axios
          .post(url)
          .then((response) => {
            if (response.data.error) {
              this.errors = response.data.error;
            }
            if (response.data.message) {
              // show success toaster.
              this.toast({
                title: "Successfully edited!",
                description: response.data.message,
                variant: "success",
              });
              this.$router.push({ name: "Agencies" });
            }
          })
          .catch((error) => console.log(error));
      }
    },
    getAgencyDetails() {
      let id = this.$route.params.id,
        url = this.generateUrl("agencies/" + id + "/details");
      if (id) {
        this.axios
          .get(url)
          .then((response) => {
            if (response.data && response.data.agency) {
              this.agency = response.data.agency;
              this.agency["isActive"] = response.data.agency.isActive
                ? true
                : false;
            }
          })
          .catch((error) => console.log(error));
      }
    },
  },
};
</script>
