<script setup>
import { SwitchRoot, SwitchThumb } from "radix-vue";
const switchState = defineModel();
</script>

<template>
  <div class="flex gap-2 items-center mt-4">
    <SwitchRoot
      id="active-toggle"
      v-model:checked="switchState"
      class="w-[42px] h-[25px] focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 flex bg-black/30 shadow-sm rounded-full relative data-[state=checked]:bg-staapurple-medium cursor-default"
    >
      <SwitchThumb
        class="block w-[21px] h-[21px] my-auto bg-gray-200 shadow-sm rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]"
      />
    </SwitchRoot>
    <label
      class="text-black text-[15px] leading-none pr-[15px] select-none"
      for="active-toggle"
    >
      {{ switchState ? "Is active" : "Inactive" }}
    </label>
  </div>
</template>
