<script setup>
import { ToastProvider } from "radix-vue";

const props = defineProps({
  label: {
    type: String,
    default: "Notification",
  },
  swipeDirection: {
    type: String,
    default: "right",
    validator: function (value) {
      return ["right", "left", "up", "down"].includes(value);
    },
  },
  swipeThreshold: {
    type: Number,
    default: 50,
  },
});
</script>

<template>
  <ToastProvider v-bind="props">
    <slot />
  </ToastProvider>
</template>
