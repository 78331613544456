<template>
  <div>
    <a
      @click.stop="runProcessDocument()"
      id="parseDocument"
      class="cursor-pointer transition p-2 mr-2 rounded bg-staagreen-medium text-white hover:bg-staagreen-dark hover:text-white"
    >
      Parse
    </a>

    <a
      @click.stop="deleteItem()"
      id="deleteDocument"
      class="cursor-pointer transition p-2 rounded bg-red-600 text-white hover:bg-red-900 hover:text-white"
    >
      <font-awesome-icon icon="trash" size="sm" />
    </a>
  </div>
</template>

<script>
import { useToast } from "@assets/vue/components/ui/staatoast/use-toast";

export default {
  name: "actionButtonsDocuments",
  props: ["id", "delete_route"],
  computed: {
    run_process_document_route() {
      return this.generateUrl(`documents/process/${this.id}`);
    },
  },
  created() {
    this.toast = useToast().toast;
  },
  methods: {
    deleteItem() {
      let id = this.id;
      let url = this.delete_route + "/" + id + "/delete";

      this.axios
        .delete(url)
        .then((response) => {
          if (response.data && response.data.status === "success") {
            this.$emit("document_deleted", {
              id: id,
              message: response.message,
            });
            // show success toaster.
            this.toast({
              title: "Success!",
              description: response.data.message,
              variant: "success",
            });
          } else {
            // show error toaster.
            this.toast({
              title: "Error!",
              description: response.data.error,
              variant: "destructive",
            });
          }
        })
        .catch((error) => console.log(error));
    },
    runProcessDocument: _.debounce(function () {
      this.toast({
        title: "Parsing the document...",
        variant: "success",
      });
      this.axios
        .get(this.run_process_document_route)
        .then((response) => {
          if (response.data) {
            if (
              typeof response.data == "string" &&
              (response.data.includes("cURL error") ||
                response.data.includes("Could not authenticate user"))
            ) {
              this.toast({
                title: "Something went wrong when parsing this document!",
                variant: "destructive",
              });
            } else {
              this.$router.push({ path: `/documents/${this.id}` });
            }
          }
        })
        .catch((error) => console.log(error));
    }, 200),
  },
};
</script>
