<template>
    <div>
        <div class="transition w-full absolute top-0 z-50 transform -translate-x-0 -translate-y-2/4 -mt-6">
            <div class="w-full p-3 text-sm bg-white rounded-lg text-center shadow-lg">
                {{ text }}
            </div>
            <div class="w-full flex justify-center">
                <div class="triangle-bottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tooltip',
    props: ['text']
}
</script>
