<template>
    <div class="md:flex md:items-center md:justify-between mt-4">
        <!-- Prev | first ... before|current|after ... last | Next -->
        <div>Showing <span class="current_page_first">{{ current_page_first }}</span> to <span class="current_page_total_count">{{ current_page_total_count }}</span> of <span class="recordsTotal">{{ recordsTotal }}</span> entries</div>
        <ul class="staa-table-pagination">
            <li @click.stop="globalQueryParams.page = globalQueryParams.page - 1; $emit('paginate')" :class="{'disabled': (globalQueryParams.page - 1) === 0}">
                {{ prev_label ? prev_label : default_prev_label }}
            </li>
            <template v-if="!pagination_range().includes(first_page)">
                <li @click.stop="globalQueryParams.page = first_page; $emit('paginate')" :class="{'active': first_page == globalQueryParams.page}">
                    {{ first_page }}
                </li>
                <li class="separator">...</li>
            </template>
            <li @click.stop="globalQueryParams.page = i; $emit('paginate')" v-for="i in pagination_range()" :class="{'active': i == globalQueryParams.page}" :key="'pagination-page-' + i">
                {{ i }}
            </li>
            <template v-if="!pagination_range().includes(pagination_last)">
                <li class="separator">...</li>
                <li @click.stop="globalQueryParams.page = pagination_last; $emit('paginate')" :class="{'active': pagination_last == globalQueryParams.page}">
                    {{ pagination_last }}
                </li>
            </template>
            <li @click.stop="globalQueryParams.page = globalQueryParams.page + 1; $emit('paginate')" :class="{'disabled': (globalQueryParams.page + 1) > pagination_last}">
                {{ next_label ? next_label : default_next_label }}
            </li>
        </ul>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'Pagination',
    props: ['current_page_first', 'current_page_total_count', 'recordsTotal', 'globalQueryParams', 'pagination', 'pagination_last', 'prev_label', 'next_label'],
    computed: {
        default_prev_label() {
            return 'Previous'
        },
        default_next_label() {
            return 'Next'
        },
        first_page() {
            return 1
        }
    },
    methods: {
        pagination_range() {
            let range = [],
                pagination_array = [],
                page = this.globalQueryParams.page,
                before = page > 1 ? [page - 1] : [],
                current = [page],
                after = []
            if (page < this.pagination_last && page !== this.pagination_last) {
                after = [page + 1]
            }
            if (page === 1 && page != this.pagination_last) {
                after =  [page + 1, page + 2, page + 3]
            }
            if (page < this.pagination_last && page !== this.pagination_last && page === 3) {
                before = [page - 2, page - 1]
            }
            if (page < this.pagination_last && page !== this.pagination_last && page === 2) {
                before = [page - 1]
                after = [page + 1, page + 2]
            }
            if (page === 1 && page != this.pagination_last && this.pagination_last === 5) {
                after =  [page + 1, page + 2, page + 3, page + 4]
            }
            if (page === this.pagination_last && this.pagination_last === 5) {
                before = []
                current = []
                after =  [page - 4, page - 3, page - 2, page - 1, page]
            }
            if (page + 1 === this.pagination_last) {
                before = []
                current = []
                after =  [page - 1, page, page + 1]
            }
            if (page === 1 && page === this.pagination_last) {
                before = []
                current = [page]
                after =  []
            }
            if (page === this.pagination_last) {
                before = []
                current = []
                after =  [page - 3, page - 2, page - 1, page]
            }
            if (page < this.pagination_last && page !== this.pagination_last && page === (this.pagination_last - 2)) {
                after = [page + 1, page + 2]
            }
            if (page < this.pagination_last && page !== this.pagination_last && page === (this.pagination_last - 1)) {
                before = [page -2, page - 1]
                current = [page]
                after = [page + 1]
            }
            if (this.pagination_last === 5) {
                before = []
                current = []
                after =  [1, 2, 3, 4, 5]
            }
            if (before.concat(current, after).length) {
                range = before.concat(current, after)
            }
            
            // Build the final pagination array.
            range.map(page => {
                if (typeof page != "undefined" && page > 0) {
                    pagination_array.push(page)
                }
            })

            return pagination_array
        },
    }
}
</script>
