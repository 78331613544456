<script setup>
import { cva } from "class-variance-authority";
import SpinningIcon from "@assets/vue/svg/SpinningIcon.vue";

const buttonVariants = cva(
  "inline-flex items-center justify-center  transition-colors disabled:pointer-events-none disabled:opacity-80",
  {
    variants: {
      variant: {
        default:
          "text-base font-medium text-slate-50 bg-staaVacancy  rounded-lg border-staaVacancy border hover:bg-staaVacancyHover focus-visible:border-black focus-visible:border-4 active:bg-staaVacancyActive",
        transparent: "text-base font-medium leading-tight text-staaVacancy",
      },
      size: {
        default: "py-2.5 px-6 w-full h-[46px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const props = defineProps({
  as: {
    type: String,
    default: "button",
  },
  variant: {
    type: String,
    default: "default",
  },
  size: {
    type: String,
    default: "default",
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <component
    :is="as"
    :class="buttonVariants({ variant, size })"
    :disabled="loading"
  >
    <SpinningIcon v-if="loading" />
    <slot v-if="!loading" />
  </component>
</template>
