<template>
<div class="m-4 p-4 border border-gray-300 rounded">
    <div>
        <h1 class="w-full flex flex-column flex-wrap items-center justify-between">
            Process All Documents
            <a @click.prevent.stop="reRun" class="text-sm cursor-pointer px-3 py-2.5 text-white hover:text-white bg-staapurple-light hover:bg-staapurple-dark font-base">
                <font-awesome-icon icon="redo" :class="animate !== false ? animate_class : ''" />
                <span class="hidden md:inline">Run again</span>
            </a>
        </h1>
        <p class="p-2 mt-2 border border-gray-300">Run process documents command</p>
    </div>
    <div class="mt-4 overflow-x-auto" v-if="processed_documents_output" v-html="processed_documents_output"></div>
    <Loading v-else type="page-content" container_class="bg-black bg-opacity-25 top-0 left-0 fixed z-70 w-screen h-screen flex items-center" color="text-staapurple-medium" />
</div>
</template>

<script>
import _ from 'lodash'
import Loading  from '../partials/Loading.vue'

export default {
    name: 'ProcessedDocumentsContent',
    components: {
        Loading,
    },
    data() {
        return {
            animate: false,
            animate_class: 'animate-spin',
            processed_documents_output: '',
            processDocumentsRoute: this.generateUrl(`documents/process/all`),
        }
    },
    mounted() {
        this.getProcessedDocuments()
    },
    methods: {
        reRun() {
            this.processed_documents_output=''
            this.animate = true
            this.getProcessedDocuments(true)
        },
        getProcessedDocuments: _.debounce(function(animate) {
            this.axios.get(this.processDocumentsRoute).then(response => {
                if (response.data) {
                    this.processed_documents_output = response.data
                }
                if (animate) {
                    this.animate = false
                }
            }).catch(error => console.log(error))
        }, 200),
    }
}
</script>
