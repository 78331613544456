<script setup>
import { computed } from "vue";
import { ToastTitle } from "radix-vue";
import { cn } from "@assets/vue/Helpers/utils";

const props = defineProps({
  class: String,
});

// `delegatedProps` is a computed property that creates a new object
// containing all properties from `props` except for `class`.
// This is used to pass all other props to the `ToastTitle` component.
const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <ToastTitle
    v-bind="delegatedProps"
    :class="cn('text-sm font-semibold', props.class)"
  >
    <slot />
  </ToastTitle>
</template>
