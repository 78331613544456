<template>
  <div id="container">
    <header-container />
    <div class="m-4 p-4 border border-gray-300 rounded">
      <h1>Agency</h1>
      <template v-if="agency">
        <div class="mt-4 grid grid-cols-custom2">
          <div class="px-2 text-center"><strong>Id </strong></div>
          <div>
            <span>{{ agency.id }}</span>
          </div>
          <div class="px-2 text-center"><strong>Name </strong></div>
          <div>
            <span>{{ agency.name }}</span>
          </div>
          <div class="px-2 text-center"><strong>Email </strong></div>
          <div>
            <span>{{ agency.email }}</span>
          </div>
          <div class="px-2 text-center"><strong>Is Active </strong></div>
          <div>
            <span>{{ agency.isActive ? "Yes" : "No" }}</span>
          </div>
          <div v-if="agency.createdAt" class="px-2 text-center">
            <strong>Created at </strong>
          </div>
          <div>
            <span>{{ prettyDate(agency.createdAt, true) }}</span>
          </div>
          <div v-if="agency.updatedAt" class="px-2 text-center">
            <strong>Updated at </strong>
          </div>
          <div>
            <span>{{ prettyDate(agency.updatedAt, true) }}</span>
          </div>
        </div>
        <div class="mt-4 flex justify-between">
          <back-or-submit
            :back="{ href: '/agencies', label: 'Back' }"
            :submit="{ label: 'Edit' }"
            @submit="
              $router.push({ name: 'EditAgency', params: { id: agency.id } })
            "
          />
          <AlertDialog>
            <template v-slot:title>
              <p class="text-2xl">Delete Agency?</p>
            </template>
            <template v-slot:description>
              <p class="text-xl">
                Are you sure you want to delete
                <span class="font-extrabold text-green-500">
                  {{ agency.name }}
                </span>
                ?
              </p>
              <p class="text-xl">All information will be lost.</p>
            </template>
            <template v-slot:action>
              <button
                class="bg-red-500 py-2 px-5 font-semibold hover:bg-red-600/90 text-slate-50 shadow-sm inline-flex h-[35px] items-center justify-center rounded-[4px] leading-none outline-none focus:shadow-[0_0_0_2px] focus:shadow-black transition-all"
                @click="confirmedDelete"
              >
                Yes, delete account
              </button>
            </template>
          </AlertDialog>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import HeaderContainer from "../../layout/HeaderContainer.vue";
import BackOrSubmit from "../../partials/BackOrSubmit.vue";
import AlertDialog from "@assets/vue/components/ui/alertdialog/AlertDialog";
import { useToast } from "@assets/vue/components/ui/staatoast/use-toast";

export default {
  name: "AgencyDetails",
  components: {
    "header-container": HeaderContainer,
    "back-or-submit": BackOrSubmit,
    AlertDialog,
  },
  mounted() {
    this.getAgencyDetails();
  },
  data() {
    return {
      showDialog: false,
      agency: {},
    };
  },
  created() {
    this.toast = useToast().toast;
  },
  computed: {
    delete_route() {
      return this.generateUrl("agencies/" + this.$route.params.id + "/delete");
    },
    confirmedDelete() {
      if (this.delete_route) {
        this.axios
          .delete(this.delete_route)
          .then((response) => {
            if (response.data.error) {
              this.toast({
                title: "Error deleting the agency",
                description: response.data.error,
                variant: "destructive",
              });
            }
            if (response.data.message) {
              this.toast({
                title: "Success!",
                description: response.data.message,
                variant: "success",
              });

              this.$router.push({ name: "Agencies" });
            }
          })
          .catch(function (error) {
            // console.log(error)
          });
      }
    },
  },
  methods: {
    getAgencyDetails() {
      let id = this.$route.params.id,
        url = this.generateUrl("agencies/" + id + "/details");
      if (id) {
        this.axios
          .get(url)
          .then((response) => {
            if (response.data) {
              this.agency = response.data.agency;
            }
          })
          .catch((error) => console.log(error));
      }
    },
  },
};
</script>
