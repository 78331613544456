<template>
<div id="container">
    <header-container />
    <processed-documents-content />
</div>
</template>

<script>
import HeaderContainer  from '../layout/HeaderContainer.vue'
import ProcessedDocumentsContent  from './ProcessedDocumentsContent.vue'

export default {
    name: 'ProcessedDocuments',
    components: {
        'header-container': HeaderContainer,
        'processed-documents-content': ProcessedDocumentsContent
    }
}
</script>
