<template>
<div class="m-4 p-4 border border-gray-300 rounded">
    <div class="w-full h-auto flex flex-column flex-wrap items-center justify-between">
        <h1>Agencies list</h1>
        <router-link :to="{name: 'CreateAgency'}" v-slot="{ navigate, href }" custom>
            <a class="transition staa-button-green right font-bold" :href="href" @click="navigate" @keypress.enter="navigate" role="button-link">
                Create a new agency
            </a>
        </router-link>
    </div>
    <div class="mt-4" v-if="dataLoaded">
        <div class="alert-no-results" v-if="agencies && agencies.length === 0">
            {{ no_results_found_message }}
        </div>
        <div class="overflow-x-auto">
            <table cellpadding="0" cellspacing="0" border="0" class="w-full staa-table">
                <thead>
                    <tr>
                        <th :align="column.align ? column.align : 'left'" v-for="(column,i) in columns" :key="'agencies-table-th-' + i" :class="column.headerClass ? column.headerClass : ''">
                            {{ column.label }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(agency,i) in agencies" :key="'agency-row-' + i">
                        <td>
                            <router-link :to="{'name': 'AgencyDetails', 'params': { 'id': agency.id}}" v-slot="{ navigate, href }" custom>
                                <a class="table-link" :href="href" @click="navigate" @keypress.enter="navigate" role="table-link">{{ agency.id }}</a>
                            </router-link>
                        </td>
                        <td>{{ agency.name }}</td>
                        <td><span class="block w-28 min-w-full break-all">{{ agency.documentCount }}</span></td>
                        <td>{{ agency.email }}</td>
                        <td>{{ agency.isActive ? 'Yes' : 'No' }}</td>
                        <td><span class="block w-28 min-w-full break-all">{{ agency.createdAt }}</span></td>
                        <td><span class="block w-28 min-w-full break-all">{{ agency.updatedAt }}</span></td>
                        <td align="right"><actionButtonsAgencies class="w-28 flex gap-1" :id="agency.id" /></td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4"><strong>Total:</strong> {{ recordsTotal }}</div>
        </div>
    </div>
    <Loading v-else id="loadingComponent" type="table" class="mt-2" />
</div>
</template>

<script>
import _ from 'lodash'
import Loading from '../partials/Loading'
import actionButtonsAgencies from '../partials/actionButtonsAgencies'

export default {
    name: 'AgenciesContent',
    components: {
        Loading,
        actionButtonsAgencies
    },
    computed: {
        columns() {
            return [{
                    label: 'Id',
                    field: 'id',
                },
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Nr documents',
                    field: 'name',
                },
                {
                    label: 'Email',
                    field: 'email',
                },
                {
                    label: 'Is active',
                    field: 'isActive',
                },
                {
                    label: 'Created at',
                    field: 'createdAt',
                },
                {
                    label: 'Updated at',
                    field: 'updatedAt',
                },
                {
                    label: 'Actions',
                    headerClass: 'no-sorting w-28',
                    align: 'right',
                    sortable: false
                }
            ]
        },
        entriesOptions() {
            return [{ value: '10', text: '10' },
                    { value: '25', text: '25' },
                    { value: '50', text: '50' },
                    { value: '100', text: '100' }
            ]
        },
        agenciesRoute() {
            return this.generateUrl(`agencies/list`)
        }
    },
    data() {
        return {
            no_results_found_message: 'No results found',
            recordsTotal: 0,
            dataLoaded: false,
            agencies: []
        }
    },
    mounted() {
        this.getAgencies()
    },
    methods: {
        getAgencies: _.debounce(function () {
            let url = this.agenciesRoute
            this.axios.get(url).then(response => {
                if (response.data && response.data.agencies) {
                    this.agencies = response.data.agencies
                    this.setDataLoaded(true)
                }
                
                this.recordsTotal = response.data.recordsTotal
            }).catch(error => console.log(error))
        }, 200),
        setDataLoaded(value) {
            this.dataLoaded = value
        },
    }
}
</script>
