<template>
<div id="container">
    <header-container />
    <documents-content />
</div>
</template>

<script>
import HeaderContainer  from '../layout/HeaderContainer.vue'
import DocumentsContent  from './DocumentsContent.vue'

export default {
    name: 'Documents',
    components: {
        'header-container': HeaderContainer,
        'documents-content': DocumentsContent
    }
}
</script>
