<template>
<div class="header-logo-wrapper">
    <router-link :to="{'name': 'Home'}" v-slot="{ navigate, href }" custom>
        <a :class="$route.name === 'Home' ? 'active' : ''" :href="href" @click="navigate" @keypress.enter="navigate" role="logo-link">Staa Core</a>
    </router-link>
</div>
</template>

<script>
export default {
    name: 'HeaderLogo'
}
</script>
