const Tools = {
    methods: {
        generateUrl(url) {
            return this.homeUrl() + url
        },
        homeUrl() {
            return window.$homeurl
        },
        getCurrentUser() {
            const user = {}
            if (window.$user) {
                user.id = window.$user.id
                user.username = window.$user.username
                user.email = window.$user.email
                user.verified = window.$user.verified
                user.roles = JSON.parse(window.$user.roles)
                user.is_authenticated_remembered = window.$user.is_authenticated_remembered
            }
            return user
        },
        formattedDateWithTime(date) {
            let formatted_date = new Date(date)
            // Manual conversion to avoid GMT conversion to a wrong time value.
            let formatted_date_time = date.split('T')[1].split('+')[0]
            
            return formatted_date.getFullYear() + '-' + ("0" + (formatted_date.getMonth() + 1)).slice(-2) + '-' + ("0" + (formatted_date.getDate())).slice(-2) + ' ' + formatted_date_time
        },
        limit_per_page() {
            return 10
        },
        toasted_duration() {
            return 5000
        },
        objectToQueryString(object) {
            var queryString = [];
            for (var property in object)
                if (object.hasOwnProperty(property)) {
                    queryString.push(encodeURIComponent(property) + "=" + encodeURIComponent(object[property]))
            }
            return queryString.join("&")
        }
    }
}

export default Tools