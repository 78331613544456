<template>
  <div class="m-4 p-4 border border-gray-300 rounded">
    <div class="w-full flex flex-column flex-wrap items-center justify-between">
      <h1>Documents</h1>
    </div>
    <div
      class="document-filters mt-2 border border-gray-300 p-3 flex flex-wrap w-full gap-4"
    >
      <ComboBox
        v-if="document_filters.statuses && document_filters.statuses.length"
        :options="document_filters.statuses"
        @update:modelValue="onDropdownInput"
        v-model="filters.status"
        id="documentFilters"
        placeholder="All Statuses"
      />
      <ComboBox
        @update:modelValue="onDropdownInput"
        id="documentFilters"
        v-model="filters.hostName"
        placeholder="All Host Names"
        v-if="document_filters.hostNames && document_filters.hostNames.length"
        :options="document_filters.hostNames"
      />
      <ComboBox
        @update:modelValue="onDropdownInput"
        id="documentFilters"
        v-model="filters.type"
        placeholder="All Types"
        v-if="document_filters.types && document_filters.types.length"
        :options="document_filters.types"
      />
      <ComboBox
        @update:modelValue="onDropdownInput"
        id="documentFilters"
        v-model="filters.language"
        placeholder="All Languages"
        v-if="document_filters.languages && document_filters.languages.length"
        :options="document_filters.languages"
      />
      <ComboBox
        @update:modelValue="onDropdownInput"
        id="documentFilters"
        v-model="filters.agency"
        placeholder="All Agencies"
        v-if="document_filters.agencies && document_filters.agencies.length"
        :options="document_filters.agencies"
      />
    </div>
    <div
      class="flex-wrap flex my-4"
      :class="
        documents && documents.length > 0
          ? 'md:justify-between'
          : 'md:justify-end'
      "
    >
      <div
        class="flex items-center mb-4 md:mb-0 gap-2"
        v-if="documents && documents.length > 0"
      >
        Show
        <select
          v-model="globalQueryParams.limit"
          class="transition border-2 border-gray-300 block rounded text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
          @change="onDropdownInput()"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        entries
      </div>
      <input
        class="transition px-3 py-2 border-2 border-gray-300 block rounded text-gray-800 focus:shadow-outline focus:border-blue-500 w-full md:w-1/4 form-control focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
        @input="onSearchQueryInput()"
        v-model="searchQuery"
        type="text"
        placeholder="Search by Id"
      />
    </div>
    <div class="alert-no-results" v-if="documents && documents.length === 0">
      {{ no_results_found_message }}
    </div>
    <div class="mt-4" v-if="dataLoaded">
      <div v-if="documents && documents.length > 0" class="overflow-x-auto">
        <table
          cellpadding="0"
          cellspacing="0"
          border="0"
          class="w-full staa-table"
        >
          <thead>
            <tr>
              <th
                :align="column.align ? column.align : 'left'"
                v-for="(column, i) in columns"
                :key="'documents-table-th-' + i"
                :class="column.headerClass ? column.headerClass : ''"
              >
                {{ column.label }}
                <span class="sorting" v-if="column.sortable !== false">
                  <span title="Asc"
                    ><font-awesome-icon
                      @click.stop="
                        globalQueryParams.page = 1;
                        globalQueryParams.sort = column.field;
                        globalQueryParams.order = 'asc';
                        getDocuments();
                      "
                      size="sm"
                      icon="chevron-up"
                  /></span>
                  <span title="Desc"
                    ><font-awesome-icon
                      @click.stop="
                        globalQueryParams.page = 1;
                        globalQueryParams.sort = column.field;
                        globalQueryParams.order = 'desc';
                        getDocuments();
                      "
                      size="sm"
                      icon="chevron-down"
                  /></span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(document, i) in documents" :key="'document-row-' + i">
              <td>
                <router-link
                  :to="{ name: 'DocumentDetails', params: { id: document.id } }"
                  v-slot="{ navigate, href }"
                  custom
                >
                  <a
                    class="table-link"
                    :href="href"
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="table-link"
                    :title="document.id"
                    >{{ document.id }}</a
                  >
                </router-link>
              </td>
              <td>
                <a
                  class="block w-96 truncate table-link"
                  target="_blank"
                  :href="document.requestUrlPath"
                  :title="document.requestUrl"
                  >{{ document.requestUrl }}</a
                >
              </td>
              <td
                @mouseover="setTooltip(document, true)"
                @mouseleave="setTooltip(document, false)"
              >
                <div class="flex w-60 min-w-full break-all relative">
                  <Tooltip
                    v-if="document.statusMessage && document.tooltip"
                    :text="document.statusMessage"
                  />
                  <div>
                    <font-awesome-icon
                      v-if="
                        document.statusMessage &&
                        document.status === 'completed-with-no-document'
                      "
                      icon="times"
                      size="sm"
                      color="red"
                      class="ml-1"
                    />
                    <font-awesome-icon
                      v-if="
                        document.statusMessage &&
                        document.status === 'completed-with-document'
                      "
                      icon="exclamation-triangle"
                      size="sm"
                      color="orange"
                    />
                    {{ document.status }}
                  </div>
                </div>
              </td>
              <td>{{ document.type }}</td>
              <td>
                <span class="block w-28 min-w-full break-all">{{
                  document.hostName
                }}</span>
              </td>
              <td>{{ document.language }}</td>
              <td>
                <span class="block w-32 min-w-full break-all">{{
                  document.agency_name
                }}</span>
              </td>
              <td>{{ document.country }}</td>
              <td>
                <span class="block w-30 min-w-full break-all">{{
                  document.createdAt
                }}</span>
              </td>
              <td>
                <span class="block w-30 min-w-full break-all">{{
                  document.updatedAt
                }}</span>
              </td>
              <td align="right">
                <actionButtonsDocuments
                  @document_deleted="deleteDocumentFromList"
                  class="w-28"
                  :id="document.id"
                  :delete_route="documents_delete_route"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="documents && documents.length > 0"
        :current_page_first="current_page_first"
        :current_page_total_count="recordsTotal"
        :recordsTotal="recordsTotal"
        :globalQueryParams="globalQueryParams"
        :pagination_last="pagination"
        @paginate="getDocuments"
      />
    </div>
    <Loading
      v-else
      id="loadingComponent"
      type="table"
      :rows="10"
      class="mt-2"
    />
  </div>
</template>

<script>
import _ from "lodash";
import Loading from "../partials/Loading";
import Tooltip from "../partials/Tooltip";
import Pagination from "../partials/Pagination";
import actionButtonsDocuments from "../partials/actionButtonsDocuments";
import ComboBox from "@assets/vue/components/ui/combobox/ComboBox";

export default {
  name: "DocumentsContent",
  components: {
    Loading,
    actionButtonsDocuments,
    Tooltip,
    Pagination,
    ComboBox,
  },
  computed: {
    columns() {
      return [
        {
          label: "Id",
          field: "id",
        },
        {
          label: "Request url",
          field: "requestUrl",
          headerClass: "w-96",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Host Name",
          field: "hostName",
        },
        {
          label: "Language",
          field: "language",
        },
        {
          label: "Agency Name",
          field: "agency_name",
        },
        {
          label: "Country",
          field: "country",
        },
        {
          label: "Created at",
          field: "createdAt",
        },
        {
          label: "Updated at",
          field: "updatedAt",
        },
        {
          label: "Actions",
          headerClass: "no-sorting",
          align: "right",
          sortable: false,
        },
      ];
    },
    entriesOptions() {
      return [
        { value: "10", text: "10" },
        { value: "25", text: "25" },
        { value: "50", text: "50" },
        { value: "100", text: "100" },
      ];
    },
    documents_delete_route() {
      return this.generateUrl("documents");
    },
  },
  data() {
    return {
      no_results_found_message: "No results found",
      globalQueryParams: {
        page: 1,
        start: 0,
        limit: this.limit_per_page(),
        status: "all-statuses",
        hostname: "all-hostnames",
        type: "all-types",
        language: "all-languges",
        agency: "all-agencies",
        order: "desc",
        sort: "createdAt",
      },

      searchQuery: "",
      isTyping: false,
      filters: [],
      document_filters: [],
      pagination: 0,
      recordsTotal: 0,
      current_page_total_count: 0,
      current_page_first: 1,

      dataLoaded: false,
      documents: [],
      documentsRoute: this.generateUrl(`documents/list`),
      documentFiltersRoute: this.generateUrl(`documents/document_filters`),
    };
  },
  mounted() {
    this.getDocumentFilters();
    this.getDocuments();
  },
  beforeDestroy: function () {},
  watch: {
    searchQuery: _.debounce(function () {
      this.setIsTyping(false);
    }, 500),
    isTyping: function (value) {
      if (!value) {
        this.getDocuments();
      }
    },
  },
  methods: {
    onSearchQueryInput() {
      this.resetPage();
      this.setIsTyping(true);
    },
    onDropdownInput() {
      this.resetPage();
      this.getDocuments();
    },
    resetPage() {
      this.globalQueryParams.page = 1;
      this.globalQueryParams.start = 0;
    },
    setIsTyping(value) {
      this.isTyping = value;
    },
    setTooltip(document, value) {
      document.tooltip = value;
    },
    deleteDocumentFromList(document) {
      // Find index of specific object using findIndex method,
      var objIndex = this.documents.findIndex((obj) => obj.id === document.id);
      // and remove the element from the documents array.
      this.documents.splice(objIndex, 1);
      this.recordsTotal--;
    },
    getDocumentFilters() {
      this.axios
        .get(this.documentFiltersRoute)
        .then((response) => {
          if (response.data) {
            this.document_filters = response.data;
          }
        })
        .catch((error) => console.log(error));
    },
    setDataLoaded(value) {
      this.dataLoaded = value;
    },
    emptyDocuments() {
      this.documents = [];
    },
    getDocuments: _.debounce(function () {
      this.queryParamsObject();

      let params = this.globalQueryParams,
        url = this.documentsRoute + "?" + this.objectToQueryString(params);

      this.setDataLoaded(false);

      this.axios
        .get(url)
        .then((response) => {
          if (response.data) {
            this.emptyDocuments();
            if (response.data.documents) {
              this.documents = response.data.documents.map((entity) => {
                let array = entity;
                array["tooltip"] = false;
                return array;
              });
            }
            if (response.data.recordsTotal) {
              this.recordsTotal = response.data.recordsTotal;
            }
            this.setDataLoaded(true);
          }
          this.pagination = Math.ceil(
            response.data.recordsTotal / this.globalQueryParams.limit
          );
          this.current_page_first = (params.page - 1) * params.limit + 1;
          this.current_page_total_count =
            params.page * params.limit > response.data.recordsTotal
              ? response.data.recordsTotal
              : params.page * params.limit;
          this.total_entries_count = response.data.recordsTotal;
        })
        .catch((error) => console.log(error));
    }, 200),
    queryParamsObject() {
      let limit = this.globalQueryParams.limit,
        page = this.globalQueryParams.page ? this.globalQueryParams.page : 1,
        offset = page ? (parseInt(page) - 1) * limit : 0,
        search_string = this.searchQuery ? this.searchQuery : "",
        status =
          this.filters && this.filters.status
            ? this.filters.status
            : "all-statuses",
        hostName =
          this.filters && this.filters.hostName
            ? this.filters.hostName
            : "all-hostnames",
        type =
          this.filters && this.filters.type ? this.filters.type : "all-types",
        language =
          this.filters && this.filters.language
            ? this.filters.language
            : "all-languages",
        agency =
          this.filters && this.filters.agency
            ? this.filters.agency
            : "all-agencies",
        order = this.globalQueryParams.order,
        sort = this.globalQueryParams.sort;

      let queryParams = {
        page: page,
        start: offset,
        limit: limit,
        search_string: search_string,
        status: status,
        hostName: hostName,
        type: type,
        language: language,
        agency: agency,
        order: order,
        sort: sort,
      };

      this.globalQueryParams = queryParams;
    },
  },
};
</script>
