<template>
<div>
    <ul class="main-menu">
        <li class="menu-item" v-for="(item, index) in main_menu.items" :key="'main-menu-li-' + index">
            <router-link :to="{'name': item.route_name}" v-slot="{ navigate, href }" custom>
                <a class="menu-link" :class="[main_menu.link_class, item.active_class]" :href="href" @click="navigate" @keypress.enter="navigate" role="main-menu-link">{{ item.name }}</a>
            </router-link>
        </li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'HeaderContainer',
    computed: {
        main_menu: function () {
            let main_menu = {
                link_class: 'capitalize',
                items: [
                    {
                        route_name: 'Agencies',
                        name: 'Agencies',
                        active_class: this.$route.name === 'Agencies' || this.$route.name === 'AgencyDetails' || this.$route.name === 'EditAgency' ? 'active' : ''
                    },
                    {
                        route_name: 'Documents',
                        name: 'Documents',
                        active_class: this.$route.name === 'Documents' || this.$route.name === 'ProcessedDocuments' || this.$route.name === 'DocumentDetails' ? 'active' : ''
                    }
                ]
            }
            return main_menu
        }
    }
}
</script>
