<script setup>
import logo from "@assets/images/logo-staa-color.png";

const props = defineProps({
  mainText: String,
  secondaryText: String,
});
</script>

<template>
  <div>
    <div class="user-content" data-qa="content-wrapper">
      <img class="ui centered logo image h-14 mt-16" :src="logo" />
      <div class="pt-2"></div>
      <p class="text-slate-900 text-2xl font-medium leading-loose text-center">
        {{ mainText }}
      </p>
      <p
        class="text-slate-400 text-sm font-normal text-center"
        data-qa="secondary-text"
      >
        {{ secondaryText }}
      </p>
      <div class="pt-4"></div>
      <slot />
    </div>
  </div>
</template>
